<template>
  <div class="add main-block">
    <div class="add__box main-block__head">
      <h1 class="add__box-title main-block__title">{{ title }} *</h1>
    </div>
    <ul class="add__items">
      <li class="add__item flex">
        <p class="add__key">Адрес забора *</p>
        <input type="text" class="add__value add__value_aligin" placeholder="Адрес" />
      </li>
      <li class="add__item flex">
        <p class="add__key">Город *</p>
        <b-form-select
          v-model="countryId"
          class="transport-add__value main__select"
          :options="list"
          text-field="name"
          value-field="id"
          @change="cityId()"
        />
        <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        <!-- <input type="text" class="add__value add__value_aligin" placeholder="Город" required> -->
      </li>
      <li class="add__item flex">
        <p class="add__key">Улица *</p>
        <input type="text" class="add__value add__value_aligin" placeholder="Улица" />
      </li>
      <li class="add__item flex">
        <p class="add__key">Номер офиса/дом *</p>
        <input type="number" class="add__value add__value_aligin" placeholder="№" />
      </li>
      <li class="add__item flex">
        <p class="add__key">Номер кв</p>
        <input type="number" class="add__value add__value_aligin" placeholder="№" />
      </li>
      <li class="add__item flex">
        <p class="add__key">Этаж</p>
        <input type="number" class="add__value add__value_aligin" placeholder="№" />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    title: {
      type: String,
      default: "Адрес",
    },
    counterpartyData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countryId: null,
    };
  },
  computed: {
    ...mapState(["citiesProducts"]),
    list() {
      if (this.citiesProducts) {
        return [
          {
            id: null,
            name: "Выберите",
            disabled: true,
          },
          ...this.citiesProducts,
        ];
      }
      return [
        {
          id: null,
          name: "Выберите",
          disabled: true,
        },
      ];
    },
  },
  watch: {
    "$route.query.id"() {
      if (!this.$route.query.id) {
        this.countryId = null;
      }
    },
  },
  created() {
    this.$store.dispatch("getCitiesProducts");
  },
  mounted() {
    if (this.counterpartyData) {
      this.citiesProducts.forEach((e) => {
        if (e.name === this.counterpartyData.city) this.countryId = e.id;
        this.$emit("cityId", this.countryId);
      });
    }
  },
  methods: {
    cityId() {
      this.$emit("cityId", this.countryId);
    },
  },
};
</script>

<style scoped>
.add {
  min-height: auto;
  padding-bottom: 30px;
}
.add__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}
.add__item {
  padding-top: 11.5px;
  height: 27.5px;
  position: relative;
}
.add__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}
.main__select-icon {
  right: 8px;
  top: 53%;
  width: 7px;
}
.add__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
}
.add__value {
  width: 48%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}
.transport-add__value {
  width: 48%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}
</style>
